import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import CleanLoader from "../../Common/CleanLoader";
import Heading from "../../Common/Heading";
import ClientsData from "./ClientsData";
import { ChromePicker, CirclePicker, SketchPicker } from "react-color";
import { uploadFileToCloudinary } from "../../../services/cloudinary";

function AddProject() {
  const navigate = useNavigate();
  const [addProjectDataLoader, setAddProjectDataLoader] = useState(false);
  const [errorBorder, setErrorBorder] = useState(false);
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  //const [client, setClient] = useState("");
  const [country, setCountry] = useState("");
  const [client, setClient] = useState({
    name: "",
    feedback: "",
  });
  const [category, setCategory] = useState("");
  const [date, setDate] = useState("");
  const [priority, setPriority] = useState(0);
  const [area, setArea] = useState("");
  const [feedback, setFeedback] = useState("");
  const [type, setType] = useState("");
  const [colorScheme, setColorScheme] = useState([
    { primary: "" },
    { sec1: "" },
    { sec2: "" },
  ]);
  const [primary, setPrimary] = useState("#ffffff");
  const [secondary, setSecondary] = useState("#ffffff");
  const [tertiary, setTertiary] = useState("#ffffff");
  const [coverOne, setCoverOne] = useState(false);
  const [coverTwo, setCoverTwo] = useState(false);
  const [gImgOne, setGImageOne] = useState(false);
  const [gImgTwo, setGImageTwo] = useState(false);
  const [gImgThree, setGImageThree] = useState(false);
  const [clientId, setClientId] = useState();

  const btoa = window.btoa(
    `${process.env.REACT_APP_PERSONAL_USERNAME}:${process.env.REACT_APP_PERSONAL_PASSWORD}`
  );

  const formSubmit = async () => {
    setAddProjectDataLoader(true);

    const projectFormData = new FormData();
    projectFormData.append("primary", primary);
    projectFormData.append("secondary1", secondary);
    projectFormData.append("secondary2", tertiary);
    projectFormData.append("name", name);
    projectFormData.append("description", description);
    projectFormData.append("category", category);
    projectFormData.append("type", type);
    projectFormData.append("location", country);
    projectFormData.append("date", date);
    projectFormData.append("priority", priority);
    projectFormData.append("client", clientId);
    projectFormData.append("feedback", feedback);
    projectFormData.append("area", area);

    try {
      const coverImages = document.querySelector("#coverPhoto").files;
      const galleryImages = document.querySelector("#galleryOne").files;

      const coverImageUploads = Array.from(coverImages).map((file) =>
        uploadFileToCloudinary(file)
      );
      const galleryImageUploads = Array.from(galleryImages).map((file) =>
        uploadFileToCloudinary(file)
      );

      const coverImageResults = await Promise.all(coverImageUploads);
      const galleryImageResults = await Promise.all(galleryImageUploads);

      if (coverImageResults.length > 0) {
        const coverImagesData = coverImageResults.map((result) => ({
          url: result.url,
          id: result.id,
        }));
        projectFormData.append("coverImages", JSON.stringify(coverImagesData));

        // projectFormData.append("coverImage", JSON.stringify({url: coverImageResults[0].url, id: coverImageResults[0].id}));
      }

      if (galleryImageResults.length > 0) {
        const galleryImagesData = galleryImageResults.map((result) => ({
          url: result.url,
          id: result.id,
        }));
        projectFormData.append("images", JSON.stringify(galleryImagesData));
      }

      const projectData = await fetch(
        `${process.env.REACT_APP_BASE_URL}/project/`,
        {
          method: "POST",
          body: projectFormData,
          headers: {
            Authorization: "Basic " + btoa,
          },
        }
      );
      const res = await projectData.json();

      if (projectData.status === 201) {
        navigate("/project");
      } else {
        console.error("Project creation failed:", res);
        // Handle error (show message to user, etc.)
      }
    } catch (error) {
      console.error("Error during form submission:", error);
    } finally {
      setAddProjectDataLoader(false);
    }
  };

  const handleImageChange = (event, imageType) => {
    const selectedFiles = event.target.files;
    const invalidFiles = Array.from(selectedFiles).filter(
      (file) => file.type !== "image/webp"
    );

    if (invalidFiles.length > 0) {
      if (imageType === "cover") {
        alert("Please select only webp files for cover photos.");
        setCoverOne(false);
      } else if (imageType === "gallery") {
        alert("Please select only webp files for gallery images.");
        setGImageOne(false);
      }
    } else {
      if (imageType === "cover") {
        setCoverOne(true);
      } else if (imageType === "gallery") {
        setGImageOne(true);
      }
    }
  };

  const [primaryButton, setPrimaryButton] = useState(false);
  const [secondaryButton, setSecondaryButton] = useState(false);
  const [tertiaryButton, setTertiaryButton] = useState(false);

  return (
    <section>
      <div className="container">
        <Heading text="Add Projects!" />
      </div>
      {loading ? (
        <CleanLoader />
      ) : (
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div className="input-com-group">
                <label className="label-class">Project Name</label>
                <input
                  className="input-group"
                  placeholder="Enter Your Name"
                  type="text"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  required
                />
              </div>
            </div>
            <div className="col-lg-6">
              <div className="input-com-group">
                <label className="label-class">Project Year</label>
                <input
                  className="input-group"
                  placeholder="Enter Project Date"
                  type="date"
                  min="2007"
                  value={date}
                  onChange={(e) => setDate(e.target.value)}
                  required
                />
              </div>
            </div>
            <div className="col-lg-12">
              <div className="input-com-group">
                <label className="label-class">Project Description</label>
                <textarea
                  className="input-group"
                  placeholder="Project Description"
                  type="text"
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                  required
                />
              </div>
            </div>
            <ClientsData
              feedback={feedback}
              setFeedback={setFeedback}
              setClientId={setClientId}
            />
            <div className="col-lg-6">
              <div className="input-com-group">
                <label className="label-class">Project Location</label>
                <input
                  className="input-group"
                  placeholder="Enter Project's Location"
                  type="text"
                  value={country}
                  onChange={(e) => setCountry(e.target.value)}
                  required
                />
              </div>
            </div>
            <div className="col-lg-6">
              <div className="input-com-group">
                <label className="lable-class">Category</label>
                <select
                  defaultValue="Revit/AutoCAD"
                  className="input-group"
                  value={type}
                  onChange={(e) => {
                    setType(e.target.value);
                  }}
                >
                  <option>Select Category</option>
                  <option>Revit/AutoCAD</option>
                  <option>Lumion/D5</option>
                  <option>SketchUp</option>
                  <option>BlueBeam</option>
                  <option>CostX</option>
                  <option>PlanSwift</option>
                </select>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="input-com-group">
                <label className="label-class">Project Category</label>
                {/* <input
                  className="input-group"
                  placeholder="Enter Project Category"
                  type="text"
                  value={category}
                  onChange={(e) => setCategory(e.target.value)}
                  required
                /> */}
                <select
                  defaultValue="Commercial"
                  className="input-group"
                  onChange={(e) => {
                    setCategory(e.target.value);
                    console.log(e.target.value);
                  }}
                >
                  <option>Select Sub Category</option>
                  <option>Commercial</option>
                  <option>Residential</option>
                </select>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="input-com-group">
                <label className="label-class">Project Priority</label>
                <input
                  className={`input-group ${errorBorder ? "error-border" : ""}`}
                  placeholder="Enter Project's Priority"
                  type="number"
                  value={priority}
                  maxLength={10}
                  max={10}
                  onChange={(e) => {
                    setPriority(e.target.value);
                  }}
                  required
                />
              </div>
            </div>
            <div className="col-lg-6">
              <div className="input-com-group">
                <label className="label-class">Project Area (In Sq. Ft)</label>
                <input
                  className="input-group"
                  placeholder="Enter Project's Area"
                  type="number"
                  value={area}
                  maxLength={10}
                  max={10}
                  onChange={(e) => {
                    setArea(e.target.value);
                  }}
                  required
                />
              </div>
            </div>
            <div className="col-lg-6">
              <div className="input-com-group">
                <label className="label-class">Primary Color</label>
                <button
                  className="position-relative input-group"
                  onClick={() => setPrimaryButton(!primaryButton)}
                >
                  {primary}
                </button>
                {primaryButton === true ? (
                  <div className="color-picker">
                    <ChromePicker
                      style={{ width: "100%" }}
                      color={primary}
                      onChange={(color) => {
                        setPrimary(color.hex);
                      }}
                    />
                  </div>
                ) : (
                  <></>
                )}
              </div>
            </div>
            <div className="col-lg-6">
              <div className="input-com-group">
                <label className="label-class">Secondary Color</label>
                <button
                  className="position-relative input-group"
                  onClick={() => setSecondaryButton(!secondaryButton)}
                >
                  {secondary}
                </button>
                {secondaryButton ? (
                  <div className="color-picker">
                    <div className="color-picker">
                      <ChromePicker
                        style={{ width: "100%" }}
                        color={secondary}
                        onChange={(color) => {
                          setSecondary(color.hex);
                        }}
                      />
                    </div>
                  </div>
                ) : (
                  <></>
                )}
              </div>
            </div>
            <div className="col-lg-6">
              <div className="input-com-group">
                <label className="label-class">Tertiary Color</label>
                <button
                  className="position-relative input-group"
                  onClick={() => setTertiaryButton(!tertiaryButton)}
                >
                  {tertiary}
                </button>
                {tertiaryButton === true ? (
                  <div className="color-picker">
                    <ChromePicker
                      style={{ width: "100%" }}
                      color={tertiary}
                      onChange={(color) => {
                        setTertiary(color.hex);
                      }}
                    />
                  </div>
                ) : (
                  <></>
                )}
              </div>
            </div>
            <div className="col-lg-12">
              <div className="input-com-group">
                <label className="label-class">Cover Photos</label>
                <div className="add-bg-image">
                  {coverOne ? (
                    <img
                      style={{ width: "32px" }}
                      src={process.env.PUBLIC_URL + "/images/correct.png"}
                      alt=""
                    />
                  ) : (
                    <img
                      style={{ width: "32px" }}
                      src={process.env.PUBLIC_URL + "/images/add.png"}
                      alt=""
                    />
                  )}
                  <input
                    type="file"
                    id="coverPhoto"
                    onChange={(event) => handleImageChange(event, "cover")}
                    name="coverImage[]"
                    required
                    multiple
                    accept="image/webp"
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-12">
              <div className="input-com-group">
                <label className="label-class">Gallery Images</label>
                <div className="add-bg-image">
                  {gImgOne ? (
                    <img
                      style={{ width: "32px" }}
                      src={process.env.PUBLIC_URL + "/images/correct.png"}
                      alt=""
                    />
                  ) : (
                    <img
                      style={{ width: "32px" }}
                      src={process.env.PUBLIC_URL + "/images/add.png"}
                      alt=""
                    />
                  )}
                  <input
                    type="file"
                    id="galleryOne"
                    onChange={(event) => handleImageChange(event, "gallery")}
                    name="images[]"
                    required
                    multiple
                    accept="image/webp"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-4 offset-lg-4">
              <div className="input-com-group">
                <button
                  type="submit"
                  onClick={() => {
                    formSubmit();
                    setAddProjectDataLoader(true);
                  }}
                  className="action-btn"
                  disabled={addProjectDataLoader}
                >
                  Add Data
                  <span
                    className={`${
                      addProjectDataLoader === false
                        ? ""
                        : "spinner-border spinner-border-sm"
                    } `}
                    style={{ marginLeft: "5px" }}
                  ></span>
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </section>
  );
}

export default AddProject;
