import React from "react";

function LinkedinInput({ setLinkedin }) {
  function handleLinkedin(e) {
    e.preventDefault();
    setLinkedin(e.target.value);
  }
  return (
    <div className="col-lg-6">
      <div className="input-com-group">
        <label className="lable-class">Linkedin</label>
        <input
          placeholder="Enter Designation"
          className="input-group"
          type="url"
          minLength="1"
          maxLength="255"
          onChange={handleLinkedin}
          required
        />
      </div>
    </div>
  );
}

export default LinkedinInput;
