import React from "react";
import { Link } from "react-router-dom";
import { addBlogs, blogs } from "../../URLs/url";
import Heading from "../Common/Heading";
import Table from "./Table/Table";

function BlogsMain() {
  return (
    <section>
      <div className="container">
        <Heading text="Blogs, baby!" />
        <div className="row">
          <div className="col-lg-4 mb-20">
            <Link to={`/${blogs}/${addBlogs}`}>
              <div className="action-btn">Add Blog</div>
            </Link>
          </div>
        </div>
      </div>
      <Table />
    </section>
  );
}
export default BlogsMain;
