import React from "react";

function Check({ setDeleteProject, setDisplayOverlay }) {
  return (
    <div className="check">
      <p className="warning-text">Are you sure you want to delete this?</p>
      <div className="buttons">
        <button className="yes-btn" onClick={() => setDeleteProject(true)}>
          Yes
        </button>
        <button
          className="no-btn"
          onClick={() => {
            setDeleteProject(false);
            setDisplayOverlay(false);
          }}
        >
          No
        </button>
      </div>
    </div>
  );
}

export default Check;
