import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { teamEdit, teamPage } from "../../../URLs/url";
import Heading from "../../Common/Heading";
import Loader from "../../Common/Loader";

function Detail() {
  const [userData, setUserData] = useState();
  const btoa = window.btoa(
    `${process.env.REACT_APP_PERSONAL_USERNAME}:${process.env.REACT_APP_PERSONAL_PASSWORD}`
  );
  const [loading, setLoading] = useState(true);
  const [userName, setUserName] = useState();
  let pathname = window.location.pathname.slice(7);
  const fetchMemberData = async () => {
    setLoading(true);
    try {
      const teamData = await fetch(
        `${process.env.REACT_APP_BASE_URL}/member/${pathname}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Basic " + btoa,
          },
        }
      );
      const res = await teamData.json();
      setUserData(res);
      setUserName(res?.name.split(" ", 2));
      if (teamData?.status === 200) {
        setLoading(false);
      }
    } catch (error) {
      console.log(console.error);
    }
  };
  useEffect(() => {
    fetchMemberData();
  }, []);

  return (
    <section>
      {loading === true ? (
        <Loader />
      ) : (
        <div className="container">
          <Heading text={`${userName[0]}'s Profile`} />
          <Link to={`/${teamPage}/${teamEdit}/:${userData?.id}`}>
            Edit {userName[0]}'s Profile
          </Link>
          <div className="row mt-50">
            <div className="col-lg-5 user-detail-section">
              <div className="user-box">
                <p className="user-role">Role</p>
                <h6 className="roles">{userData?.designation}</h6>
              </div>
              {userData?.linkedin === null ? (
                <></>
              ) : (
                <div className="user-box">
                  <p className="user-role">Linkedin</p>
                  <h6 className="roles">{userData?.linkedin}</h6>
                </div>
              )}
            </div>
            <div className="col-lg-1"></div>
            <div className="col-lg-6">
              <img width="100%" src={userData?.image} alt="user-img" />
            </div>
          </div>
        </div>
      )}
    </section>
  );
}

export default Detail;
