import React from "react";

function ImageInput() {
  const handleImageChange = (event) => {
    const selectedFile = event.target.files[0];

    if (selectedFile && selectedFile.type !== "image/webp") {
      alert("Please select only webp files for the display picture.");

      event.target.value = null;
    }
  };

  return (
    <div className="col-lg-6">
      <div className="input-com-group">
        <label className="lable-class">Display Picture</label>
        <input
          placeholder="Enter Designation"
          className="input-group"
          type="file"
          name="image[]"
          id="image"
          onChange={handleImageChange}
          accept="image/webp"
        />
      </div>
    </div>
  );
}

export default ImageInput;
