import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { blogs, clients, project, teamPage } from "../../URLs/url";
import Dashboard from "./Dashoard/Dashboard";

function Home() {
  let navigate = useNavigate();
  useEffect(() => {
    let user = "";
    if (localStorage.getItem("user")) {
      user = localStorage.getItem("user").replace(/"/g, "");
    }
    if (user !== "Admin") {
      navigate("/login");
    }
  }, []);
  // const [name, setName] = useState("Hammad");
  const [name, setName] = useState(true);
  return (
    <div className="container">
      <div className="row">
        <div className="col-lg-12" style={{ margin: "40px 0px" }}>
          <h2 className="dash-h1">Welcome, {name ? "Hamad" : "Oh Juggar"}</h2>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-3">
          <div className="input-com-group">
            <button className="action-btn" onClick={() => setName(!name)}>
              Turn Me {name ? "On" : "Off"}
            </button>
          </div>
        </div>
      </div>
      <div className="row mt-50">
        <div className="col-lg-4">
          <Link to={`/${teamPage}`}>
            <div className="action-btn">Teams</div>
          </Link>
        </div>
        <div className="col-lg-4">
          <Link to={`/${project}`}>
            <div className="action-btn">Projects</div>
          </Link>
        </div>
        <div className="col-lg-4">
          <Link to={`/${clients}`}>
            <div className="action-btn">Clients</div>
          </Link>
        </div>
        <div className="col-lg-4">
          <Link to={`/${blogs}`}>
            <div className="action-btn">Blogs</div>
          </Link>
        </div>
      </div>
      <Dashboard />
    </div>
  );
}

export default Home;
