import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import CleanLoader from "../../Common/CleanLoader";
import Heading from "../../Common/Heading";

function EditClient() {
  const [clientData, setClientData] = useState();
  const [clientDataName, setClientDataName] = useState("");
  const [clientName, setClientName] = useState("");
  const [disableButton, setDisableButton] = useState(false);
  const [clientStatusUpdate, setClientStatusUpdate] = useState(false);
  const [emptyString, setEmptyString] = useState(true);

  let pathname = window.location.pathname.slice(21);

  const btoa = window.btoa(
    `${process.env.REACT_APP_PERSONAL_USERNAME}:${process.env.REACT_APP_PERSONAL_PASSWORD}`
  );

  let navigate = useNavigate();

  function handleClientName(e) {
    e.preventDefault();
    if (e.target.value !== "") {
      setEmptyString(false);
    }
    setClientName(e.target.value);
  }

  const fetchClientDetail = async () => {
    try {
      const clientResData = await fetch(
        `${process.env.REACT_APP_BASE_URL}/client/${pathname}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Basic " + btoa,
          },
        }
      );
      const res = await clientResData.json();
      setClientData(res);
      setClientDataName(res?.name.split(" ", 2));
    } catch (error) {
      console.log(console.error);
    }
  };

  useEffect(() => {
    fetchClientDetail();
  });

  const onFormSubmit = async () => {
    const formData = new FormData();
    formData.append("name", clientName);
    try {
      const teamData = await fetch(
        `${process.env.REACT_APP_BASE_URL}/client/${pathname}`,
        {
          method: "PUT",
          body: formData,
          headers: {
            Authorization: "Basic " + btoa,
          },
        }
      );
      const res = await teamData.json();
      console.log(res);
      if (teamData?.status === 200) {
        setClientStatusUpdate(false);
        navigate("/clients");
      }
    } catch (error) {
      console.log(console.error);
    }
  };

  return (
    <section>
      {clientData === undefined ? (
        <CleanLoader />
      ) : (
        <div className="container">
          <Heading text={`Edit ${clientDataName[0]}'s Detail`} />
          <div className="row">
            <div className="col-lg-8">
              <div className="row">
                <div className="col-lg-6">
                  <div className="input-com-group">
                    <label className="lable-class">Name</label>
                    <input
                      placeholder={clientData?.name}
                      className="input-group"
                      type="text"
                      onChange={handleClientName}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-4 offset-lg-4">
              <button
                type="submit"
                onClick={() => {
                  onFormSubmit();
                  setDisableButton(true);
                  setClientStatusUpdate(true);
                }}
                className={`action-btn ${
                  disableButton === true || emptyString === true
                    ? "opacity-03"
                    : "opacity-01"
                }`}
              >
                Update Information
                <span
                  className={`${
                    clientStatusUpdate === false
                      ? ""
                      : "spinner-border spinner-border-sm"
                  } `}
                  style={{ marginLeft: "5px" }}
                ></span>
              </button>
            </div>
          </div>
        </div>
      )}
    </section>
  );
}

export default EditClient;
