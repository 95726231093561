import React from "react";
import { Link } from "react-router-dom";
import { addProject, project } from "../../URLs/url";
import Heading from "../Common/Heading";
import Table from "./Table/Table";

function Main() {
  return (
    <section>
      <div className="container">
        <Heading text="Projects, baby!" />
        <div className="row">
          <div className="col-lg-4 mb-20">
            <Link to={`/${project}/${addProject}`}>
              <div className="action-btn">Add Project</div>
            </Link>
          </div>
        </div>
      </div>
      <Table />
    </section>
  );
}
export default Main;
