import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { isEmail, isStrongPassword } from "validator";

function Login() {
  let navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showErrorMessage, setShowErrorMessage] = useState(false);

  const [emailCheck, setEmailCheck] = useState(true);
  const [passwordCheck, setPasswordCheck] = useState(true);

  const [disable, setDisable] = useState(true);

  function onEmailChange(e) {
    setEmailCheck(false);
    e.preventDefault();
    setEmail(e.target.value);
    if (isEmail(e.target.value)) {
      setEmailCheck(true);
    }
  }
  function onPasswordChange(e) {
    setPasswordCheck(false);
    e.preventDefault();
    setPassword(e.target.value);
    if (isStrongPassword(e.target.value)) {
      setPasswordCheck(true);
    }
  }

  useEffect(() => {
    if (passwordCheck === true && emailCheck === true) {
      setDisable(false);
    }
    localStorage.clear();
  });

  const onFormSubmit = async () => {
    console.log();
    if (
      process.env.REACT_APP_USER_LOGIN === email &&
      password === process.env.REACT_APP_USER_PASSWORD
    ) {
      navigate("/");
      localStorage.setItem("user", JSON.stringify("Admin"));
    } else {
      setShowErrorMessage(true);
    }
    // const username = "superuser";
    // const password = "dastkaar-website";
    // const btoa = window.btoa(`${username}:${password}`);
    // try {
    //   const teamData = await fetch("https://api.dastkaar.studio", {
    //     method: "GET",
    //     // headers: headers,
    //     headers: {
    //       "Content-Type": "application/json",
    //       Authorization: "Basic " + btoa,
    //     },
    //   });
    //   const res = await teamData.json();

    //   console.log(res);
    // } catch (error) {
    //   console.log(console.error);
    // }

    // navigate("/");
    // localStorage.setItem("user", JSON.stringify("Juggar"));
  };

  return (
    <div className="container login-container">
      <div className="row login-row">
        <div
          className="col-lg-6"
          style={{ paddingRight: "0px", paddingLeft: "0px" }}
        >
          <div className="login-img">
            <img
              style={{ width: "100%" }}
              src={process.env.PUBLIC_URL + "/images/logo.png"}
              alt="login"
            />
          </div>
        </div>
        <div
          className="col-lg-6"
          style={{ paddingLeft: "0px", paddingRight: "0px" }}
        >
          <div className="login-padding">
            <div className="login-screen">
              <h2 className="login-heading">Login</h2>
              <div className="input-com-group">
                <label className="label-class">Email</label>
                <input
                  className={`input-group ${
                    emailCheck === false ? "error-border" : ""
                  }`}
                  placeholder="Enter Your Email"
                  type="email"
                  value={email}
                  onChange={onEmailChange}
                />
              </div>
              <div className="input-com-group">
                <label className="label-class">Password</label>
                <input
                  className={`input-group ${
                    passwordCheck === false ? "error-border" : ""
                  }`}
                  placeholder="Enter Your Password"
                  type="password"
                  value={password}
                  onChange={onPasswordChange}
                />
              </div>
              {showErrorMessage === false ? (
                <></>
              ) : (
                <p className="error-text">Wrong Username or Password</p>
              )}
              <button
                onClick={onFormSubmit}
                className={`action-btn ${
                  disable === true ? "opacity-03" : "opacity-01"
                }`}
                disabled={disable}
              >
                Login
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;
