import React, { useEffect, useState } from "react";

function Dashboard() {
  const [dashboard, setDashboard] = useState();
  const [loading, setLoading] = useState(false);
  const username = process.env.REACT_APP_PERSONAL_USERNAME;
  const password = process.env.REACT_APP_PERSONAL_PASSWORD;
  const btoa = window.btoa(`${username}:${password}`);

  const fetchTeamData = async () => {
    setLoading(true);
    try {
      const projectData = await fetch(
        `${process.env.REACT_APP_BASE_URL}/project/`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Basic " + btoa,
          },
        }
      );
      const res = await projectData.json();
      console.log(res);
      //   setTeam(res);
      if (projectData?.status === 200) {
        setLoading(false);
      }
    } catch (error) {
      console.log(console.error);
    }
  };

  useEffect(() => {
    fetchTeamData();
  }, []);
  return (
    <section>
      <div className="container">
        <div className="row">
          <div className="col-lg-1"></div>
          <div className="col-lg-10">
            <div className="row">
              <div className="col-lg-3"></div>
              <div className="col-lg-3"></div>
              <div className="col-lg-3"></div>
            </div>
          </div>
          <div className="col-lg-1"></div>
        </div>
      </div>
    </section>
  );
}

export default Dashboard;
