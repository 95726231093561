import React, { useState } from "react";

function Search({ myFunction }) {
  const [searchValue, setSearchValue] = useState("");
  return (
    <div className="w-100 position-relative">
      <input
        id="search"
        placeholder="Search Team Member"
        className="input-group"
        type="text"
        value={searchValue}
        onChange={(e) => setSearchValue(e.target.value)}
        onKeyUp={myFunction}
      />
      {/* <img
        className="search-icon"
        style={{ cursor: "pointer" }}
        onClick={() => setSearchValue("")}
        src={process.env.PUBLIC_URL + "/images/cross.png"}
      /> */}
    </div>
  );
}

export default Search;
