import React from "react";
import { Link } from "react-router-dom";
import { teamDetailPage, teamPage, teamEdit } from "../../URLs/url";
import SmallLoader from "../Common/SmallLoader";

function Table({ team, loading, setOverlayActive, setTeamMemberId }) {
  return (
    <>
      {loading === true ? (
        <SmallLoader />
      ) : (
        <table className="table table-responsive table-fix">
          <thead className="thead">
            <tr>
              <th scope="col">Id</th>
              <th scope="col">Name</th>
              <th scope="col">Role</th>
              <th scope="col">LinkedIn</th>
              <th scope="col">Image</th>
              <th scope="col">Actions</th>
            </tr>
          </thead>
          <tbody id="table">
            {team?.map((i, index) => (
              <tr key={i?.id}>
                <th scope="row">{index + 1}</th>
                <td>{i?.name}</td>
                <td>{i?.designation}</td>
                <td>
                  <a href={i?.linkedin} target="_blank">
                    Visit
                  </a>
                </td>
                <td>
                  <div className="table-img-container">
                    <img src={i?.image} className="table-img" />
                  </div>
                </td>
                <td>
                  <img
                    onClick={() => {
                      setOverlayActive(true);
                      setTeamMemberId(i?.id);
                    }}
                    style={{ width: "30px" }}
                    src={process.env.PUBLIC_URL + "/images/delete.png"}
                    className="table-img cursor-pointer"
                  />
                  <Link to={`/${teamPage}/${teamEdit}/:${i?.id}`}>
                    <img
                      style={{ width: "30px" }}
                      src={process.env.PUBLIC_URL + "/images/edit.png"}
                      className="table-img cursor-pointer"
                    />
                  </Link>
                  <Link to={`/${teamPage}/:${i.id}`}>
                    <img
                      style={{ width: "30px" }}
                      src={process.env.PUBLIC_URL + "/images/link.png"}
                      className="table-img cursor-pointer"
                    />
                  </Link>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </>
  );
}

export default Table;
