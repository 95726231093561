import React, { useEffect, useState } from "react";
import Alert from "../Common/Alert";
import Heading from "../Common/Heading";
import { Link, useNavigate } from "react-router-dom";
import Search from "./Search";
import Table from "./Table";
import CheckDelete from "../Common/CheckDelete";

function Team() {
  const [team, setTeam] = useState([]);
  const [loading, setLoading] = useState(false);

  const btoa = window.btoa(
    `${process.env.REACT_APP_PERSONAL_USERNAME}:${process.env.REACT_APP_PERSONAL_PASSWORD}`
  );

  const [overlayActive, setOverlayActive] = useState(false);
  const [teamMemberId, setTeamMemberId] = useState();

  const fetchTeamData = async () => {
    setLoading(true);
    try {
      const teamData = await fetch("https://api.dastkaar.studio/member/", {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Basic " + btoa,
        },
      });
      const res = await teamData.json();
      // console.log(res);
      setTeam(res);
      if (teamData?.status === 200) {
        setLoading(false);
      }
    } catch (error) {
      console.log(console.error);
    }
  };

  useEffect(() => {
    fetchTeamData();
  }, []);

  let navigate = useNavigate();
  useEffect(() => {
    let user = "";
    if (localStorage.getItem("user")) {
      user = localStorage.getItem("user").replace(/"/g, "");
    }
    if (user !== "Admin") {
      navigate("/login");
    }
  }, []);

  function myFunction() {
    var input, filter, ul, li, a, i, txtValue;
    input = document.getElementById("search");
    filter = input.value.toUpperCase();
    ul = document.getElementById("table");
    li = ul.getElementsByTagName("tr");

    for (i = 0; i < li.length; i++) {
      a = li[i].getElementsByTagName("td")[0];
      txtValue = a.textContent || a.innerText;
      if (txtValue.toUpperCase().indexOf(filter) > -1) {
        li[i].style.display = "";
      } else {
        li[i].style.display = "none";
      }
    }
  }

  return (
    <>
      {overlayActive === true ? (
        <div className="loader-container" id="display">
          <CheckDelete
            setOverlayActive={setOverlayActive}
            teamMemberId={teamMemberId}
            btoa={btoa}
          />
        </div>
      ) : (
        <></>
      )}
      <div className="container">
        <Heading text="Your Team" />
      </div>
      <div className="container">
        <div className="row">
          <div className="col-lg-6">
            <Alert />
          </div>
        </div>
      </div>
      <div className="container team-section">
        <div className="row">
          <div className="col-lg-5 start-but-center">
            <Search myFunction={myFunction} />
          </div>
          <div className="col-lg-3"></div>
          <div className="col-lg-4 end-but-center">
            <Link to="/team/addTeam">
              <div className="action-btn">Add Member</div>
            </Link>
          </div>
        </div>
        <div className="row mt-20">
          <div className="col-lg-12">
            <Table
              setOverlayActive={setOverlayActive}
              setTeamMemberId={setTeamMemberId}
              team={team}
              loading={loading}
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default Team;
