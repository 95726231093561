import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import CleanLoader from "../../Common/CleanLoader";
import Heading from "../../Common/Heading";
import { uploadFileToCloudinary } from "../../../services/cloudinary";
import ReactQuill, { Quill } from "react-quill";
import "react-quill/dist/quill.snow.css";
import "react-quill/dist/quill.bubble.css";
import { blogs } from "../../../URLs/url";

function AddBlog() {
  const blogId = window.location.pathname.slice(18);
  const navigate = useNavigate();
  const [addProjectDataLoader, setAddProjectDataLoader] = useState(false);
  const [loading, setLoading] = useState(false);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [time, setTime] = useState("");
  const [category, setCategory] = useState("Revit/AutoCAD");
  const [author, setAuthor] = useState("");
  const [priority, setPriority] = useState(false);
  const [slug, setSlug] = useState("");
  const [coverOne, setCoverOne] = useState(false);
  const [blog, setBlog] = useState("");
  const [status, setStatus] = useState(true);
  const [redirect, setRedirect] = useState("");

  const btoa = window.btoa(
    `${process.env.REACT_APP_PERSONAL_USERNAME}:${process.env.REACT_APP_PERSONAL_PASSWORD}`
  );

  const formSubmit = async () => {
    setAddProjectDataLoader(true);

    const blogsFormData = new FormData();
    blogsFormData.append("title", title);
    blogsFormData.append("description", description);
    blogsFormData.append("category", category);
    blogsFormData.append("time", time);
    blogsFormData.append("author", author);
    blogsFormData.append("priority", priority ? "True" : "False");
    blogsFormData.append("status", status ? "True" : "False");
    blogsFormData.append("slug", slug);
    blogsFormData.append("blog", blog);
    blogsFormData.append("redirect", redirect);

    try {
      if (blogId) {
      } else {
        const coverImages = document.querySelector("#coverPhoto").files;

        const coverImageUploads = Array.from(coverImages).map((file) =>
          uploadFileToCloudinary(file)
        );

        const coverImageResults = await Promise.all(coverImageUploads);

        if (coverImageResults.length > 0) {
          const coverImagesData = coverImageResults.map((result) => ({
            url: result.url,
            id: result.id,
          }));
          blogsFormData.append("cover_image", JSON.stringify(coverImagesData));
        }
      }

      const method = blogId ? "PUT" : "POST";
      const apiURL = blogId
        ? `${process.env.REACT_APP_BASE_URL}/blog/${blogId}`
        : `${process.env.REACT_APP_BASE_URL}/blog/`;
      const projectData = await fetch(apiURL, {
        method: method,
        body: blogsFormData,
        headers: {
          Authorization: "Basic " + btoa,
        },
      });
      const res = await projectData.json();

      if (projectData.status === 201 || 200) {
        navigate("/blogs");
      } else {
        console.error("Project creation failed:", res);
        // Handle error (show message to user, etc.)
      }
    } catch (error) {
      console.error("Error during form submission:", error);
    } finally {
      setAddProjectDataLoader(false);
    }
  };

  const handleImageChange = (event, imageType) => {
    const selectedFiles = event.target.files;
    const invalidFiles = Array.from(selectedFiles).filter(
      (file) => file.type !== "image/webp"
    );

    if (invalidFiles.length > 0) {
      if (imageType === "cover") {
        alert("Please select only webp files for cover photos.");
        setCoverOne(false);
      }
    } else {
      if (imageType === "cover") {
        setCoverOne(true);
      }
    }
  };

  // hljs.registerLanguage("typescript", javascript);

  var Size = Quill.import("attributors/style/size");
  // Add fonts to whitelist
  const Font = Quill.import("formats/font");
  // We do not add Aref Ruqaa since it is the default
  Font.whitelist = ["reddit", "roboto", "merriweather"];
  Quill.register(Font, true);
  Size.whitelist = [
    "9px",
    "10px",
    "11px",
    "12px",
    "14px",
    "16px",
    "18px",
    "20px",
    "22px",
    "24px",
    "26px",
    "28px",
  ];
  Quill.register(Size, true);
  const modules = {
    toolbar: [
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      [{ font: ["reddit", "roboto", "merriweather"] }],
      [
        {
          size: [
            "12px",
            "14px",
            "16px",
            "18px",
            "20px",
            "22px",
            "24px",
            "26px",
            "28px",
          ],
        },
      ],
      ["bold", "italic", "underline", "strike", "blockquote", "code-block"],
      [{ align: ["right", "center", "justify"] }],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
      ],
      [
        {
          color: [
            "violet",
            "blue",
            "sky",
            "red",
            "orange",
            "yellow",
            "green",
            "black",
            "#00ffbb",
            "#071360",
            "#0464a4",
          ],
        },
      ],
      [
        {
          background: [
            "violet",
            "blue",
            "sky",
            "red",
            "orange",
            "yellow",
            "green",
            "black",
            "#00ffbb",
            "#071360",
            "#0464a4",
          ],
        },
      ],
      ["link", "image"],
      ["clean"],
    ],
    // syntax: {
    //   highlight: (text) => hljs.highlight("typescript", text).value,
    // },
  };

  const formats = [
    "header",
    "bold",
    "font",
    "font-size",
    "size",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "code-block",
    "align",
    "list",
    "bullet",
    "indent",
    "color",
    "background",
    "link",
    "image",
    "clean",
  ];

  const fetchBlogData = async (blogId) => {
    setLoading(true);
    try {
      const data = await fetch(
        `${process.env.REACT_APP_BASE_URL}/blog/${blogId}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Basic " + btoa,
          },
        }
      );
      const res = await data.json();
      setTitle(res?.title);
      setBlog(res?.blog);
      setDescription(res?.description);
      setPriority(res?.priority);
      setStatus(res?.status);
      setTime(res?.time);
      setAuthor(res?.author);
      setCategory(res?.category);
      setSlug(res?.slug);
      setRedirect(res?.redirect);

      if (data?.status === 201 || 200) {
        setLoading(false);
      }
    } catch (error) {
      console.log(console.error);
    }
  };

  useEffect(() => {
    fetchBlogData(blogId);
  }, [blogId]);

  const fnDeleteProject = async () => {
    setLoading(true);
    const data = await fetch(
      `${process.env.REACT_APP_BASE_URL}/blog/${blogId}`,
      {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Basic " + btoa,
        },
      }
    );
    if (data?.status === 204 || 200) {
      setLoading(false);
      navigate(`/${blogs}`);
    }
  };

  return (
    <section>
      <div className="container">
        <Heading text={blogId ? "Edit Blog!" : "Add Blog!"} />
      </div>
      {loading ? (
        <CleanLoader />
      ) : (
        <div className="container">
          <div className="row">
            {blogId && (
              <div className="col-lg-12 delete-end">
                <h2>Delete this blog</h2>
                <img
                  src={process.env.PUBLIC_URL + "/images/delete.png"}
                  alt=""
                  style={{
                    cursor: "pointer",
                    width: "40px",
                    height: "40px",
                    marginRight: "30px",
                  }}
                  onClick={fnDeleteProject}
                />
              </div>
            )}
            <div className="col-lg-6">
              <div className="input-com-group">
                <label className="label-class">Title</label>
                <input
                  className="input-group"
                  placeholder="Enter Title"
                  type="text"
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                  required
                />
              </div>
            </div>
            <div className="col-lg-6">
              <div className="input-com-group">
                <label className="label-class">Author</label>
                <input
                  className="input-group"
                  placeholder="Enter Author Name"
                  type="text"
                  min="2007"
                  value={author}
                  onChange={(e) => setAuthor(e.target.value)}
                  required
                />
              </div>
            </div>
            <div className="col-lg-6">
              <div className="input-com-group">
                <label className="label-class">Reading Time</label>
                <input
                  className="input-group"
                  placeholder="Enter Reading Time"
                  type="text"
                  value={time}
                  onChange={(e) => setTime(e.target.value)}
                  required
                />
              </div>
            </div>
            <div className="col-lg-6">
              <div className="input-com-group">
                <label className="lable-class">Category</label>
                <select
                  defaultValue="Revit/AutoCAD"
                  className="input-group"
                  value={category}
                  onChange={(e) => {
                    setCategory(e.target.value);
                  }}
                >
                  <option>Latest Articles</option>
                  <option>Featured Articles</option>
                  <option>Priorty Articles</option>
                  <option value="DesignDrafting">
                    Architectural Design & Drafting
                  </option>
                  <option value="ModellingRendering">
                    3D Modelling & Rendering
                  </option>
                  <option value="EstimationCostManagement">
                    Estimation and Cost Management
                  </option>
                </select>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="input-com-group">
                <label className="label-class">Slug</label>
                <input
                  className="input-group"
                  placeholder="Enter Slug"
                  type="text"
                  value={slug}
                  onChange={(e) => {
                    setSlug(e.target.value);
                  }}
                  required
                />
              </div>
            </div>
            <div className="col-lg-6">
              <div className="input-com-group">
                <label className="lable-class">Redirect</label>
                <input
                  className="input-group"
                  placeholder="Enter Redirect URL"
                  type="text"
                  value={redirect}
                  onChange={(e) => {
                    setRedirect(e.target.value);
                  }}
                  required
                />
              </div>
            </div>
            <div className="col-lg-6">
              <div className="input-com-group">
                <label className="lable-class">Status</label>
                <select
                  defaultValue="true"
                  className="input-group"
                  value={status}
                  onChange={(e) => {
                    setStatus(e.target.value);
                  }}
                >
                  <option>Select Category</option>
                  <option>true</option>
                  <option>false</option>
                </select>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="input-com-group">
                <label className="lable-class">Priority</label>
                <select
                  defaultValue="true"
                  className="input-group"
                  value={priority}
                  onChange={(e) => {
                    setPriority(e.target.value);
                  }}
                >
                  <option>Select Category</option>
                  <option>true</option>
                  <option>false</option>
                </select>
              </div>
            </div>
            <div className="col-lg-12">
              <div className="input-com-group">
                <label className="label-class">SEO Description</label>
                <ReactQuill
                  value={description}
                  onChange={setDescription}
                  className="text-editer"
                  modules={modules}
                  formats={formats}
                />
              </div>
            </div>
            <div className="col-lg-12">
              <div className="input-com-group">
                <label className="label-class">Blog </label>
                <ReactQuill
                  value={blog}
                  onChange={setBlog}
                  className="text-editer"
                  modules={modules}
                  formats={formats}
                />
              </div>
            </div>
            {blogId ? (
              <></>
            ) : (
              <div className="col-lg-12">
                <div className="input-com-group">
                  <label className="label-class">Cover Photo</label>
                  <div className="add-bg-image">
                    {coverOne ? (
                      <img
                        style={{ width: "32px" }}
                        src={process.env.PUBLIC_URL + "/images/correct.png"}
                        alt=""
                      />
                    ) : (
                      <img
                        style={{ width: "32px" }}
                        src={process.env.PUBLIC_URL + "/images/add.png"}
                        alt=""
                      />
                    )}
                    <input
                      type="file"
                      id="coverPhoto"
                      onChange={(event) => handleImageChange(event, "cover")}
                      name="coverImage[]"
                      required
                      multiple
                      accept="image/webp"
                    />
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className="row">
            <div className="col-lg-4 offset-lg-4">
              <div className="input-com-group">
                <button
                  type="submit"
                  onClick={() => {
                    formSubmit();
                    setAddProjectDataLoader(true);
                  }}
                  className="action-btn"
                  disabled={addProjectDataLoader}
                >
                  {blogId ? "Edit Blog" : "Add Blog"}
                  <span
                    className={`${
                      addProjectDataLoader === false
                        ? ""
                        : "spinner-border spinner-border-sm"
                    } `}
                    style={{ marginLeft: "5px" }}
                  ></span>
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </section>
  );
}

export default AddBlog;
