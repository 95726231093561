import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { editProject, editProjectImages, project } from "../../../URLs/url";
import Check from "../../Common/Check";
import CleanLoader from "../../Common/CleanLoader";
import Heading from "../../Common/Heading";

function ProjectDetail() {
  let projectId = window.location.pathname?.slice(24);
  const [projectDetail, setProjectsDetail] = useState();
  const [clientsData, setClientsData] = useState();
  const [clientName, setClientName] = useState("");

  let navigate = useNavigate();

  const btoa = window.btoa(
    `${process.env.REACT_APP_PERSONAL_USERNAME}:${process.env.REACT_APP_PERSONAL_PASSWORD}`
  );

  const fetchClient = async () => {
    try {
      const clientData = await fetch(
        `${process.env.REACT_APP_BASE_URL}/client/`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Basic " + btoa,
          },
        }
      );
      const response = await clientData.json();
      setClientsData(response);
    } catch (error) {
      console.log(console.error);
    }
  };

  const fetchProjectDetail = async () => {
    try {
      const data = await fetch(
        `${process.env.REACT_APP_BASE_URL}/project/${projectId}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Basic " + btoa,
          },
        }
      );
      const res = await data.json();
      console.log(res);
      setProjectsDetail(res);
      if (data?.status === 201) {
        // setLoading(false);
      }
    } catch (error) {
      console.log(console.error);
    }
  };

  const fnDeleteProject = async () => {
    const data = await fetch(
      `${process.env.REACT_APP_BASE_URL}/project/${projectId}`,
      {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Basic " + btoa,
        },
      }
    );
    if (data?.status === 204) {
      navigate(`/${project}`);
    }
  };

  useEffect(() => {
    fetchProjectDetail();
    fetchClient();
  }, []);

  const [displayOverlay, setDisplayOverlay] = useState(false);
  const [deleteProject, setDeleteProject] = useState();

  useEffect(() => {
    if (deleteProject === true) {
      fnDeleteProject();
    }
  }, [deleteProject]);

  useEffect(() => {
    if (projectDetail !== undefined && clientsData !== undefined) {
      let nameOfClient = clientsData?.filter(
        (i) => i?.id === projectDetail?.feedback?.client
      );
      setClientName(nameOfClient[0]?.name);
    }
  });

  return (
    <>
      {projectDetail !== undefined ? (
        <>
          {displayOverlay ? (
            <div className="loader-container" id="display">
              <Check
                setDeleteProject={setDeleteProject}
                setDisplayOverlay={setDisplayOverlay}
              />
            </div>
          ) : (
            <></>
          )}
          <div className="container">
            <Heading text={`${projectDetail?.name}'s Detail`} />
            <div
              className="row mb-20"
              style={{ display: "flex", alignItems: "center" }}
            >
              <div className="col-lg-3">
                <div style={{ display: "flex" }} className="mb-20">
                  <img
                    src={process.env.PUBLIC_URL + "/images/delete.png"}
                    alt=""
                    style={{
                      cursor: "pointer",
                      width: "30px",
                      marginRight: "10px",
                    }}
                    onClick={() => setDisplayOverlay(true)}
                  />
                  <Link to={`/${project}/${editProject}/:${projectId}`}>
                    <img
                      src={process.env.PUBLIC_URL + "/images/edit.png"}
                      alt=""
                      style={{ cursor: "pointer", width: "30px" }}
                    />
                  </Link>
                </div>
              </div>
              <div className="col-lg-6"></div>
              <div className="col-lg-3">
                <Link
                  to={`/${project}/${editProjectImages}/:${projectId}`}
                  state={{
                    data: projectDetail,
                  }}
                >
                  <div className="action-btn mb-20">Edit Images</div>
                </Link>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12">
                <div className="input-com-group">
                  <p className="project-detail-title">Description</p>
                  <h5 className="project-detail-info">
                    {projectDetail?.description}
                  </h5>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="input-com-group">
                  <p className="project-detail-title">Year</p>
                  <h5 className="project-detail-info">
                    {projectDetail?.date?.slice(0, -6)}
                  </h5>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="input-com-group">
                  <p className="project-detail-title">Area</p>
                  <h5 className="project-detail-info">{projectDetail?.area}</h5>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="input-com-group">
                  <p className="project-detail-title">Catgeroy</p>
                  <h5 className="project-detail-info">{projectDetail?.type}</h5>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="input-com-group">
                  <p className="project-detail-title">Sub Category</p>
                  <h5 className="project-detail-info">
                    {projectDetail?.category}
                  </h5>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="input-com-group">
                  <p className="project-detail-title">Client Name</p>
                  <h5 className="project-detail-info">{clientName}</h5>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="input-com-group">
                  <p className="project-detail-title">Priority</p>
                  <h5 className="project-detail-info">
                    {projectDetail?.priority}
                  </h5>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="input-com-group">
                  <p className="project-detail-title">Client Feedback</p>
                  <h5 className="project-detail-info">
                    {projectDetail?.feedback?.feedback}
                  </h5>
                </div>
              </div>

              <div className="col-lg-12 mt-20 mb-20">
                <h2>Color Scheme</h2>
              </div>
              <div className="col-lg-4">
                <div className="input-com-group">
                  <p className="project-detail-title">Primary</p>
                  <h5 className="project-detail-info">
                    <div
                      className="color-circle"
                      style={{ backgroundColor: projectDetail?.primary }}
                    ></div>
                    {projectDetail?.primary}
                  </h5>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="input-com-group">
                  <p className="project-detail-title">Secondary</p>
                  <h5 className="project-detail-info">
                    <div
                      className="color-circle"
                      style={{ backgroundColor: projectDetail?.secondary1 }}
                    ></div>
                    {projectDetail?.secondary1}
                  </h5>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="input-com-group">
                  <p className="project-detail-title">Tertiary</p>
                  <h5 className="project-detail-info">
                    <div
                      className="color-circle"
                      style={{ backgroundColor: projectDetail?.secondary2 }}
                    ></div>
                    {projectDetail?.secondary2}
                  </h5>
                </div>
              </div>
              <div className="col-lg-12 mt-20 mb-20">
                <h2>Pictures</h2>
              </div>
              {projectDetail?.coverImages.map((i, index) => (
                <div className="col-lg-6 mt-20 mb-20" key={index}>
                  <img
                    src={i?.image_url ? i.image_url : i?.image}
                    alt="cover"
                    style={{ width: "100%" }}
                  />
                </div>
              ))}
              {projectDetail?.images.map((i, index) => (
                <div className="col-lg-6 mt-20 mb-20" key={index}>
                  <img
                    src={i?.image_url ? i.image_url : i?.image}
                    alt="cover"
                    style={{ width: "100%" }}
                  />
                </div>
              ))}
            </div>
          </div>
        </>
      ) : (
        <CleanLoader />
      )}
    </>
  );
}

export default ProjectDetail;
