import React, { useEffect, useState } from "react";

function ClientsData({ feedback, setFeedback, setClientId }) {
  const [clients_Data, setClientsData] = useState();

  const btoa = window.btoa(
    `${process.env.REACT_APP_PERSONAL_USERNAME}:${process.env.REACT_APP_PERSONAL_PASSWORD}`
  );

  const fetchClientsData = async () => {
    try {
      const clientsData = await fetch(
        `${process.env.REACT_APP_BASE_URL}/client/`,
        {
          method: "GET",
          // body: formData,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Basic " + btoa,
          },
        }
      );
      const res = await clientsData.json();
      console.log(res);
      setClientsData(res);

      if (clientsData?.status === 201) {
        // setLoading(false);
      }
    } catch (error) {
      console.log(console.error);
    }
  };

  useEffect(() => {
    fetchClientsData();
  }, []);

  return (
    <>
      <div className="col-lg-12">
        <div className="input-com-group">
          <label className="label-class">Client Name</label>
          <select
            className="input-group"
            placeholder="Select Client"
            onChange={(e) => {
              setClientId(e.target.value);
            }}
          >
            {clients_Data === undefined ? (
              <></>
            ) : (
              <>
                <option defaultValue>Select a client</option>
                {clients_Data?.map((i, index) => (
                  <option value={i?.id} key={index}>
                    {i.name}
                  </option>
                ))}
              </>
            )}
          </select>
        </div>
      </div>
      <div className="col-lg-12">
        <div className="input-com-group">
          <label className="label-class">Client Feedback</label>
          <textarea
            className="input-group"
            placeholder="Client Feedback"
            value={feedback}
            required
            onChange={(e) => setFeedback(e.target.value)}
          />
        </div>
      </div>
    </>
  );
}

export default ClientsData;
