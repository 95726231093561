import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

function CheckDelete({ setOverlayActive, teamMemberId, btoa }) {
  const [deleteMember, setDeleteMember] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  let navigate = useNavigate();
  const deleteTeamMemberFunction = async () => {
    try {
      const clientsData = await fetch(
        `${process.env.REACT_APP_BASE_URL}/member/${teamMemberId}`,
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Basic " + btoa,
          },
        }
      );
      if (clientsData?.status === 204) {
        navigate("/team");
      }
    } catch (error) {
      console.log(console.error);
      setErrorMessage(true);
    }
  };
  return (
    <div className="check">
      {errorMessage === true ? (
        <p className="warning-text">
          An error occured, please try again later.
        </p>
      ) : (
        <>
          <p className="warning-text">Are you sure you want to delete this?</p>
          <div className="buttons">
            <button
              className="yes-btn"
              onClick={() => {
                deleteTeamMemberFunction();
                setDeleteMember(true);
              }}
            >
              Yes
              <span
                className={`${
                  deleteMember === false
                    ? ""
                    : "spinner-border spinner-border-sm"
                } `}
                style={{ marginLeft: "5px" }}
              ></span>
            </button>
            <button
              className="no-btn"
              onClick={() => {
                setOverlayActive(false);
              }}
            >
              No
            </button>
          </div>
        </>
      )}
    </div>
  );
}

export default CheckDelete;
