import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import CleanLoader from "../../Common/CleanLoader";
import { project, projectDetail } from "../../../URLs/url";

function Table() {
  const [projectsData, setProjectsData] = useState();
  let navigate = useNavigate();

  const btoa = window.btoa(
    `${process.env.REACT_APP_PERSONAL_USERNAME}:${process.env.REACT_APP_PERSONAL_PASSWORD}`
  );

  const fetchProjectData = async () => {
    try {
      const data = await fetch(`${process.env.REACT_APP_BASE_URL}/project/`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Basic " + btoa,
        },
      });
      const res = await data.json();
      setProjectsData(res);

      if (data?.status === 201) {
        // setLoading(false);
      }
    } catch (error) {
      console.log(console.error);
    }
  };

  useEffect(() => {
    fetchProjectData();
  }, []);

  const fnDeleteProject = async (projectId) => {
    const data = await fetch(
      `${process.env.REACT_APP_BASE_URL}/project/${projectId}`,
      {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Basic " + btoa,
        },
      }
    );
    if (data?.status === 204) {
      navigate(`/${project}`);
    }
  };
  return (
    <div className="container">
      <div className="row">
        <div className="col-lg-12">
          {projectsData !== undefined ? (
            <>
              {projectsData?.length === 0 ? (
                <>
                  <img
                    src={process.env.PUBLIC_URL + "/images/no-work.svg"}
                    alt="no-work"
                    className="w-100"
                  />
                  <p
                    className="warning-text mt-50"
                    style={{ fontSize: "2rem", fontWeight: "600" }}
                  >
                    No Projects Found 😞
                  </p>
                </>
              ) : (
                <table className="table">
                  <thead className="thead">
                    <tr>
                      <th scope="col">Id</th>
                      <th scope="col">Name</th>
                      <th scope="col">Priority</th>
                      <th scope="col">Category</th>
                      <th scope="col">Sub Category</th>
                      <th scope="col">Location</th>
                      <th scope="col">Images</th>
                      <th scope="col">Action</th>
                    </tr>
                  </thead>
                  <tbody id="table">
                    {projectsData.map((i, index) => (
                      <tr key={index}>
                        <th scope="row">{index + 1}</th>
                        <td>{i?.name}</td>
                        <td>{i?.priority}</td>
                        <td>{i?.type}</td>
                        <td>{i?.category}</td>
                        <td>{i?.location}</td>
                        <td className="d-flex">
                          <div className="project-img-container z-3">
                            <img
                              className="w-100"
                              src={
                                i?.images[0]?.image_url
                                  ? i?.images[0]?.image_url
                                  : i?.images[0]?.image
                              }
                              alt="image"
                            />
                          </div>
                          <div className="project-img-container second-img z-2">
                            <img
                              className="w-100"
                              src={
                                i?.images[0]?.image_url
                                  ? i?.images[0]?.image_url
                                  : i?.images[0]?.image
                              }
                              alt="image"
                            />
                          </div>
                        </td>
                        <td>
                          <Link to={`/${project}/${projectDetail}/:${i?.slug}`}>
                            <img
                              style={{ width: "30px" }}
                              src={process.env.PUBLIC_URL + "/images/link.png"}
                              alt=""
                            />
                          </Link>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              )}
            </>
          ) : (
            <CleanLoader />
          )}
        </div>
      </div>
    </div>
  );
}

export default Table;
