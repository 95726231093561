import React from "react";

function CleanLoader() {
  return (
    <>
      <div className="loader-container-2">
        <div className="loader-2"></div>
      </div>
    </>
  );
}

export default CleanLoader;
