import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import CleanLoader from "../../Common/CleanLoader";
import { blogs, editBlogs } from "../../../URLs/url";

function Table() {
  const [blogsData, setBlogsData] = useState();
  let navigate = useNavigate();

  const btoa = window.btoa(
    `${process.env.REACT_APP_PERSONAL_USERNAME}:${process.env.REACT_APP_PERSONAL_PASSWORD}`
  );

  const fetchBlogData = async () => {
    try {
      const data = await fetch(`${process.env.REACT_APP_BASE_URL}/blog/`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Basic " + btoa,
        },
      });
      const res = await data.json();

      if (data?.status === 201 || 200) {
        setBlogsData(res);
        // setLoading(false);
      }
    } catch (error) {
      console.log(console.error);
    }
  };
  console.log("blogsData", blogsData);

  useEffect(() => {
    fetchBlogData();
  }, []);

  return (
    <div className="container">
      <div className="row">
        <div className="col-lg-12">
          {blogsData !== undefined ? (
            <>
              {blogsData?.length === 0 ? (
                <>
                  <img
                    src={process.env.PUBLIC_URL + "/images/no-work.svg"}
                    alt="no-work"
                    className="w-100"
                  />
                  <p
                    className="warning-text mt-50"
                    style={{ fontSize: "2rem", fontWeight: "600" }}
                  >
                    No Projects Found 😞
                  </p>
                </>
              ) : (
                <table className="table">
                  <thead className="thead">
                    <tr>
                      <th scope="col">Id</th>
                      <th scope="col">Title</th>
                      <th scope="col">Author</th>
                      <th scope="col">Slug</th>
                      <th scope="col">Category</th>
                      <th scope="col">Priority</th>
                      <th scope="col">Status</th>
                      <th scope="col">Action</th>
                    </tr>
                  </thead>
                  <tbody id="table">
                    {blogsData?.map((i, index) => (
                      <tr key={index}>
                        <th scope="row">{index + 1}</th>
                        <td>{i?.title}</td>
                        <td>{i?.author}</td>
                        <td>{i?.slug}</td>
                        <td>{i?.category}</td>
                        <td>{i?.priority ? "True" : "False"}</td>
                        <td>{i?.status ? "True" : "False"}</td>
                        <td>
                          <Link to={`/${blogs}/${editBlogs}/:${i?.slug}`}>
                            <img
                              style={{ width: "30px" }}
                              src={process.env.PUBLIC_URL + "/images/link.png"}
                              alt=""
                            />
                          </Link>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              )}
            </>
          ) : (
            <CleanLoader />
          )}
        </div>
      </div>
    </div>
  );
}

export default Table;
