import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { addClient, clients } from "../../URLs/url";
import CleanLoader from "../Common/CleanLoader";
import Heading from "../Common/Heading";
import Table from "./Table";
import CheckDeleteClient from "../Common/CheckDeleteClient";

function Clients() {
  const [clientsDataArr, setClients] = useState();
  const [loading, setLoading] = useState(false);
  const [overlay, setOverlay] = useState(false);
  const [clientId, setClientId] = useState();
  let navigate = useNavigate();
  const btoa = window.btoa(
    `${process.env.REACT_APP_PERSONAL_USERNAME}:${process.env.REACT_APP_PERSONAL_PASSWORD}`
  );
  const fetchClients = async () => {
    setLoading(true);
    try {
      const clientsData = await fetch(
        `${process.env.REACT_APP_BASE_URL}/client/`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Basic " + btoa,
          },
        }
      );
      const res = await clientsData.json();
      console.log(res);
      setClients(res);

      if (clientsData?.status === 200) {
        setLoading(false);
      }
    } catch (error) {
      console.log(console.error);
    }
  };

  useEffect(() => {
    fetchClients();
  }, []);

  useEffect(() => {
    let user = "";
    if (localStorage.getItem("user")) {
      user = localStorage.getItem("user").replace(/"/g, "");
    }
    if (user !== "Admin") {
      navigate("/login");
    }
  }, []);

  const deleteClient = async (i) => {
    setLoading(true);
    try {
      const clientsData = await fetch(
        `${process.env.REACT_APP_BASE_URL}/client/${i}`,
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Basic " + btoa,
          },
        }
      );
      const res = await clientsData.json();
      setClients(res);
      if (clientsData?.status === 204) {
        navigate("/clients");
      }
    } catch (error) {
      console.log(console.error);
    }
  };

  return (
    <>
      {loading ? (
        <CleanLoader />
      ) : (
        <>
          {overlay === true ? (
            <div className="loader-container" id="display">
              <CheckDeleteClient
                setOverlay={setOverlay}
                clientId={clientId}
                btoa={btoa}
              />
            </div>
          ) : (
            <></>
          )}
          <div className="container">
            <Heading text="Clients 😍" />
            <div className="row">
              <div className="col-lg-4"></div>
              <div className="col-lg-4">
                <Link to={`/${clients}/${addClient}`}>
                  <div className="action-btn mb-20">Add</div>
                </Link>
              </div>
              <div className="col-lg-4"></div>
            </div>
            {clientsDataArr === undefined ? (
              <CleanLoader />
            ) : (
              <>
                <Table
                  btoa={btoa}
                  clientsDataArr={clientsDataArr}
                  deleteClient={deleteClient}
                  setOverlay={setOverlay}
                  setClientId={setClientId}
                />
              </>
            )}
          </div>
        </>
      )}
    </>
  );
}

export default Clients;
