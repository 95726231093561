import React, { useEffect, useState } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import AddClients from "./Pages/Client/Add/AddClients";
import Clients from "./Pages/Client/Clients";
import EditClient from "./Pages/Client/EditClient/EditClient";
import Loader from "./Pages/Common/Loader";
import Backdrop from "./Pages/Common/Nav/Backdrop";
import Navbar from "./Pages/Common/Nav/Navbar";
import Sidedrawer from "./Pages/Common/Nav/Sidedrawer";
import Home from "./Pages/Home/Home";
import Login from "./Pages/Login/Login";
import AddProject from "./Pages/Project/Add/AddProject";
import ProjectDetail from "./Pages/Project/Detail/ProjectDetail";
import ProjectEdit from "./Pages/Project/EditProject/Edit";
import EditProject from "./Pages/Project/EditProject/Edit";
import EditImages from "./Pages/Project/EditProject/EditImages";
import Main from "./Pages/Project/Main";
import AddTeam from "./Pages/Team/Add/AddTeam";
import Detail from "./Pages/Team/Detail/Detail";
import Edit from "./Pages/Team/Edit/Edit";
import Team from "./Pages/Team/Team";
import {
  addBlogs,
  addClient,
  addProject,
  blogs,
  clients,
  editBlogs,
  editProject,
  editProjectImages,
  project,
  projectDetail,
  teamDetailPage,
  teamEdit,
  teamPage,
} from "./URLs/url";
import BlogsMain from "./Pages/Blogs/Main";
import AddBlog from "./Pages/Blogs/Add/addBlog";

function App() {
  const [sideDrawerOpen, setSideDrawerOpen] = useState(false);
  function drawerToggleClickHandler() {
    setSideDrawerOpen(!sideDrawerOpen);
  }
  function backdropClickHandler() {
    setSideDrawerOpen(!sideDrawerOpen);
  }
  let backdrop;
  if (sideDrawerOpen) {
    backdrop = <Backdrop click={backdropClickHandler} />;
  }

  return (
    <React.Fragment>
      <BrowserRouter>
        <Navbar drawerClickHandler={drawerToggleClickHandler} />
        <Sidedrawer
          show={sideDrawerOpen}
          drawerClickHandler={drawerToggleClickHandler}
        />
        {backdrop}
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/" element={<Home />} />
          <Route path={`/${teamPage}`} element={<Team />} />
          <Route
            path={`/${teamPage}/:${teamDetailPage}`}
            element={<Detail />}
          />
          <Route path={`/${clients}`} element={<Clients />} />
          <Route path={`/${clients}/${addClient}`} element={<AddClients />} />
          <Route path={`/${clients}/editClient/:id`} element={<EditClient />} />

          <Route path={`/${blogs}`} element={<BlogsMain />} />
          <Route path={`/${blogs}/${addBlogs}`} element={<AddBlog />} />
          <Route path={`/${blogs}/${editBlogs}/:id`} element={<AddBlog />} />

          <Route path={`/${project}`} element={<Main />} />
          <Route path={`/${project}/${addProject}`} element={<AddProject />} />
          <Route
            path={`${project}/${projectDetail}/:id`}
            element={<ProjectDetail />}
          />
          <Route
            path={`/${project}/${editProject}/:id`}
            element={<ProjectEdit />}
          />
          <Route
            path={`/${project}/${editProjectImages}/:id`}
            element={<EditImages />}
          />
          <Route path={`/${teamPage}/${teamEdit}/:id`} element={<Edit />} />
          <Route path={`/${teamPage}/addTeam`} element={<AddTeam />} />
          <Route path="/loader" element={<Loader />} />
        </Routes>
      </BrowserRouter>
    </React.Fragment>
  );
}

export default App;
