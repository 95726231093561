export const uploadFileToCloudinary = async (file) => {
  const preset_key = "rfmsgbkd";
  const cloud_name = "dpqb55zhy";
  const formData = new FormData();
  formData.append("file", file);
  formData.append("upload_preset", preset_key);
  const response = await fetch(
    `https://api.cloudinary.com/v1_1/${cloud_name}/image/upload`,
    {
      method: "POST",
      body: formData,
    }
  );
  const data = await response.json();
  return { url: data.url, id: data.public_id };
};
