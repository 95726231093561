import React from "react";
import { liveWebsite } from "../../URLs/url";

function Alert() {
  return (
    <div className="alert-box">
      <div className="alert-icon">
        <img
          className="alert-img"
          src={process.env.PUBLIC_URL + "/images/lightbulb.png"}
        />
      </div>
      <p className="alert-text">
        Team members shown here are directly connected with the website. Any
        changes will be reflected on the{" "}
        <span className="span-yellow">
          <a target="_blank" href={liveWebsite}>
            live website
          </a>
        </span>{" "}
        as well.
      </p>
    </div>
  );
}
export default Alert;
