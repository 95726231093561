import React, { useState } from "react";
import { Link } from "react-router-dom";

function Table({
  clientsDataArr,
  deleteClient,
  btoa,
  setOverlay,
  setClientId,
}) {
  return (
    <div className="row">
      {clientsDataArr?.length === 0 ? (
        <div className="col-lg-6 offset-lg-3">
          <img
            className="w-100"
            src={process.env.PUBLIC_URL + "/images/no-work.svg"}
            alt=""
          />
          <p className="warning-text mt-50">No clients found</p>
        </div>
      ) : (
        <div className="col-lg-4 offset-lg-4">
          <table className="table">
            <thead className="thead">
              <tr>
                <th scope="col">Id</th>
                <th scope="col">Name</th>
                <th scope="col">Action</th>
              </tr>
            </thead>
            <tbody id="table">
              {clientsDataArr?.map((i, index) => (
                <tr key={i?.id}>
                  <th scope="row">{index + 1}</th>
                  <td>{i?.name}</td>
                  <td>
                    <img
                      onClick={() => {
                        setOverlay(true);
                        setClientId(i?.id);
                      }}
                      className="cursor-pointer"
                      style={{ width: "30px" }}
                      src={process.env.PUBLIC_URL + "/images/delete.png"}
                      alt="delete"
                    />
                    <Link to={`/clients/editClient/:${i?.id}`}>
                      <img
                        className="cursor-pointer"
                        style={{ width: "30px" }}
                        src={process.env.PUBLIC_URL + "/images/edit.png"}
                        alt="edit"
                      />
                    </Link>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
}

export default Table;
