import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Heading from "../../Common/Heading";

function AddClients() {
  const [client, setClient] = useState("");
  const [loading, setLoading] = useState(false);
  const [disableButton, setDisableButton] = useState(false);
  const [addClientLoader, setAddClientLoader] = useState(false);
  let navigate = useNavigate();
  const btoa = window.btoa(
    `${process.env.REACT_APP_PERSONAL_USERNAME}:${process.env.REACT_APP_PERSONAL_PASSWORD}`
  );
  const formSubmit = async () => {
    setLoading(true);
    try {
      const clientsData = await fetch(
        `${process.env.REACT_APP_BASE_URL}/client/`,
        {
          method: "POST",
          body: JSON.stringify({
            name: client,
          }),
          headers: {
            "Content-Type": "application/json",
            Authorization: "Basic " + btoa,
          },
        }
      );
      const res = await clientsData.json();
      console.log(res);
      if (clientsData?.status === 201) {
        setLoading(false);
        navigate("/clients");
      }
    } catch (error) {
      console.log(console.error);
    }
  };

  return (
    <div className="container">
      <Heading text="Clients 😍" />
      <div className="row">
        <div className="col-lg-6 offset-lg-3">
          <div className="input-com-group">
            <label className="label-class">Name</label>
            <input
              className="input-group"
              placeholder="Enter Clients' Name"
              type="text"
              value={client}
              onChange={(e) => setClient(e.target.value)}
              required
            />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-4 offset-lg-4">
          <div className="input-com-group">
            <button
              type="submit"
              onClick={() => {
                formSubmit();
                setAddClientLoader(true);
                setDisableButton(true);
              }}
              disabled={disableButton}
              className={`action-btn ${
                disableButton ? "opacity-03" : "opacity-01"
              }`}
            >
              Add Client
              <span
                className={`${
                  addClientLoader === false
                    ? ""
                    : "spinner-border spinner-border-sm"
                } `}
                style={{ marginLeft: "5px" }}
              ></span>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddClients;
