import React from "react";
import { Link, useNavigate } from "react-router-dom";
import Toggle from "./Toggle";

function Navbar(props) {
  let user = localStorage?.getItem("user")?.replace(/"/g, "");
  let navigate = useNavigate();
  return (
    <header className="toolbar">
      <nav className="toolbar-navigation">
        {localStorage?.getItem("user") === null ? (
          <></>
        ) : (
          <Toggle click={props.drawerClickHandler} />
        )}
        <Link to="/">
          <div className="toolbar-logo">
            <img
              style={{ width: "70px" }}
              src={`${process.env.PUBLIC_URL}/images/logo.png`}
              alt="menu-icon"
            />
          </div>
        </Link>
        <div className="spacer"></div>
        <div className="toolbar-navigation-items">
          <ul>
            {localStorage?.getItem("user") === null ? (
              <></>
            ) : (
              <li
                style={{ cursor: "pointer" }}
                onClick={() => {
                  localStorage.clear();
                  navigate("/login");
                }}
              >
                {user}
                <img
                  src={`${process.env.PUBLIC_URL}/images/power-off.png`}
                  alt="menu-icon"
                />
              </li>
            )}
          </ul>
        </div>
      </nav>
    </header>
  );
}

export default Navbar;
