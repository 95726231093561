export const liveWebsite = "https://dastkaar.studio";

export const teamDetailPage = "id";
export const teamPage = "team";
export const teamEdit = "teamEdit";

export const project = "project";
export const projectDetail = "projectDetail";
export const editProject = "editProject";
export const addProject = "addProject";
export const editProjectImages = "editProjectImages";

export const clients = "clients";
export const addClient = "addClient";

export const blogs = "blogs";
export const addBlogs = "addBlogs";
export const editBlogs = "editBlogs";