import React from "react";

function CoverImage({
  res,
  setDisplayOverlay,
  setImageId,
  setSelectedType,
  setCoverMap,
}) {
  return (
    <div className="row">
      {res !== undefined ? (
        <>
          {res?.coverImages?.map((i, index) => (
            <div className="col-lg-6" key={index}>
              <div className="border-yellow-2 mt-20 mb-20">
                <div className="edit-image">
                  <div className="edit-icons">
                    <img
                      className="delete"
                      src={process.env.PUBLIC_URL + "/images/delete.png"}
                      alt="delete"
                      onClick={() => {
                        setDisplayOverlay(true);
                        setImageId(i?.id);
                        setSelectedType("coverImages");
                      }}
                    />
                    <div className="file-input">
                      <input
                        type="file"
                        className={`action-btn-2 coverImages${i?.id}`}
                        id="coverImages"
                        // id={`coverImage${i?.id}`}
                        name="coverImages[]"
                        placeholder="Upload"
                        onChange={(e) => {
                          setCoverMap(true);
                          setSelectedType("coverImages");
                          setImageId(i?.id);
                        }}
                      />
                    </div>
                  </div>
                  <img
                    className="w-100"
                    src={i?.image_url ? i.image_url : i?.image}
                    alt="image"
                  />
                </div>
              </div>
            </div>
          ))}
        </>
      ) : (
        <></>
      )}
    </div>
  );
}
export default CoverImage;
