import React from "react";

function Heading({ text }) {
  return (
    <div className="row">
      <div className="col-lg-12">
        <h2 className="big-heading">{text}</h2>
      </div>
    </div>
  );
}

export default Heading;
