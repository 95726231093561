import React from "react";

function DesignationInput({ setDesignation }) {
  function handleDesignation(e) {
    e.preventDefault();
    setDesignation(e.target.value);
  }
  return (
    <div className="col-lg-6">
      <div className="input-com-group">
        <label className="lable-class">Designation</label>
        <input
          placeholder="Enter Designation"
          className="input-group"
          type="text"
          minLength="1"
          maxLength="255"
          onChange={handleDesignation}
          required
        />
      </div>
    </div>
  );
}

export default DesignationInput;
