import React from "react";
import { Link } from "react-router-dom";

import { project, clients, teamPage, blogs } from "../../../URLs/url";

const Sidedrawer = (props) => {
  let drawerClasses = ["side-drawer"];
  if (props.show) {
    drawerClasses = ["side-drawer open"];
  }
  // console.log(props)
  return (
    <nav className={drawerClasses} onClick={props.drawerClickHandler}>
      <div>
        <img
          style={{ width: "100%" }}
          src={process.env.PUBLIC_URL + "/images/logo.png"}
          alt=""
        />
      </div>
      <ul>
        <Link to="/">
          <li>Home</li>
        </Link>
        <Link to={`/${project}`}>
          <li>Projects</li>
        </Link>
        <Link to={`/${clients}`}>
          <li>Clients</li>
        </Link>
        <Link to={`/${teamPage}`}>
          <li>Team</li>
        </Link>
        <Link to={`/${blogs}`}>
          <li>Blogs</li>
        </Link>
      </ul>
    </nav>
  );
};

export default Sidedrawer;
