import React, { useEffect, useState } from "react";
import Heading from "../../Common/Heading";
import { useLocation, useNavigate } from "react-router-dom";
import Check from "../../Common/Check";
import { project, projectDetail } from "../../../URLs/url";
import Loader from "../../Common/Loader";
import MainImage from "./MainImage";
import CoverImage from "./CoverImage";
import { uploadFileToCloudinary } from "../../../services/cloudinary";

function EditImages() {
  const location = useLocation();
  const res = location?.state?.data;
  const projectId = location?.state?.data?.id;
  const slug = location?.state?.data?.slug;
  const [overlay, setDisplayOverlay] = useState(false);
  const [deleteImage, setDeleteImage] = useState(false);
  const [imageId, setImageId] = useState();
  const navigate = useNavigate();
  const [selectedType, setSelectedType] = useState("");
  const [coverMap, setCoverMap] = useState(false);
  const [loading, setLoading] = useState(false);
  const [coverOne, setCoverOne] = useState(false);
  const [gImgOne, setGImageOne] = useState(false);

  const btoa = window.btoa(
    `${process.env.REACT_APP_PERSONAL_USERNAME}:${process.env.REACT_APP_PERSONAL_PASSWORD}`
  );
  const fnDeleteImage = async () => {
    if (selectedType === "coverImages") {
      const data = await fetch(
        `${process.env.REACT_APP_BASE_URL}/cover-image/${imageId}`,
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Basic " + btoa,
          },
        }
      );
      if (data?.status === 204) {
        navigate(`/${project}/${projectDetail}/:${res?.slug}`);
      }
    } else {
      const data = await fetch(
        `${process.env.REACT_APP_BASE_URL}/project-image/${imageId}`,
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Basic " + btoa,
          },
        }
      );
      if (data?.status === 204 || 200 || 201) {
        navigate(`/${project}/${projectDetail}/:${res?.slug}`);
      }
    }
  };
  const handleImageChange = (event, imageType) => {
    const selectedFiles = event.target.files;
    const invalidFiles = Array.from(selectedFiles).filter(
      (file) => file.type !== "image/webp"
    );

    if (invalidFiles.length > 0) {
      if (imageType === "cover") {
        alert("Please select only webp files for cover photos.");
        setCoverOne(false);
      } else if (imageType === "gallery") {
        alert("Please select only webp files for gallery images.");
        setGImageOne(false);
      }
    } else {
      if (imageType === "cover") {
        setCoverOne(true);
      } else if (imageType === "gallery") {
        setGImageOne(true);
      }
    }
  };

  const fnEditImage = async (e) => {
    e.preventDefault();
    setLoading(true);
    if (selectedType === "coverImages") {
      const coverImageId = document.querySelector(`.coverImages${imageId}`);

      const { id, url } = await uploadFileToCloudinary(coverImageId?.files[0]);

      const formData = new FormData();
      formData.append("image_url", url);
      formData.append("image_id", id);
      formData.append("project", projectId);
      try {
        const teamData = await fetch(
          `${process.env.REACT_APP_BASE_URL}/cover-image/${imageId}`,
          {
            method: "PUT",
            body: formData,
            headers: {
              Authorization: "Basic " + btoa,
            },
          }
        );
        const res = await teamData.json();
        if (teamData?.status === 200 || 201) {
          setLoading(false);
          navigate(`/${project}/${projectDetail}/:${slug}`);
        }
      } catch (error) {
        console.log("error", console.error);
      }
    } else {
      const projectImageId = document.querySelector(`.projectImage${imageId}`);
      const { id, url } = await uploadFileToCloudinary(
        projectImageId?.files[0]
      );

      const formData = new FormData();
      formData.append("image_url", url);
      formData.append("image_id", id);
      formData.append("project", projectId);
      try {
        const editProjectImageData = await fetch(
          `${process.env.REACT_APP_BASE_URL}/project-image/${imageId}`,
          {
            method: "PUT",
            body: formData,
            headers: {
              Authorization: "Basic " + btoa,
            },
          }
        );
        const res = await editProjectImageData.json();
        if (editProjectImageData?.status === 200 || 201) {
          setLoading(false);
          navigate(`/${project}/${projectDetail}/:${slug}`);
        }
      } catch (error) {
        console.log(console.error);
      }
    }
  };

  const addGalleryImages = async () => {
    setLoading(true);

    const projectFormData = new FormData();
    projectFormData.append("project", projectId);

    try {
      const coverImages = document.querySelector("#coverPhoto").files;
      const galleryImages = document.querySelector("#galleryOne").files;

      const coverImageUploads = Array.from(coverImages).map((file) =>
        uploadFileToCloudinary(file)
      );
      const galleryImageUploads = Array.from(galleryImages).map((file) =>
        uploadFileToCloudinary(file)
      );

      const coverImageResults = await Promise.all(coverImageUploads);
      const galleryImageResults = await Promise.all(galleryImageUploads);

      if (coverImageResults.length > 0) {
        const coverImagesData = coverImageResults.map((result) => ({
          url: result.url,
          id: result.id,
        }));
        // projectFormData.append("coverImages", JSON.stringify(coverImagesData));ß
      }

      if (galleryImageResults.length > 0) {
        const galleryImagesData = galleryImageResults.map((result) => ({
          url: result.url,
          id: result.id,
        }));
        projectFormData.append("images", JSON.stringify(galleryImagesData));
      }

      const projectData = await fetch(
        `${process.env.REACT_APP_BASE_URL}/project-image/220`,
        {
          method: "POST",
          body: projectFormData,
          headers: {
            Authorization: "Basic " + btoa,
          },
        }
      );
      const res = await projectData.json();

      if (projectData.status === 200 || 201) {
        navigate(`/${project}/${projectDetail}/:${slug}`);
      } else {
        console.error("Project creation failed:", res);
      }
    } catch (error) {
      console.error("Error during form submission:", error);
    } finally {
      setLoading(false);
    }
  };
  const addCoverImages = async () => {
    setLoading(true);

    const projectFormData = new FormData();
    projectFormData.append("project", projectId);

    try {
      const coverImages = document.querySelector("#coverPhoto").files;

      const coverImageUploads = Array.from(coverImages).map((file) =>
        uploadFileToCloudinary(file)
      );

      const coverImageResults = await Promise.all(coverImageUploads);

      if (coverImageResults.length > 0) {
        const coverImagesData = coverImageResults.map((result) => ({
          url: result.url,
          id: result.id,
        }));
        projectFormData.append("images", JSON.stringify(coverImagesData));
      }

      const projectData = await fetch(
        `${process.env.REACT_APP_BASE_URL}/cover-image/220`,
        {
          method: "POST",
          body: projectFormData,
          headers: {
            Authorization: "Basic " + btoa,
          },
        }
      );
      const res = await projectData.json();

      if (projectData.status === 200 || 201) {
        navigate(`/${project}/${projectDetail}/:${slug}`);
      } else {
        console.error("Project creation failed:", res);
      }
    } catch (error) {
      console.error("Error during form submission:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (deleteImage === true) {
      fnDeleteImage();
    }
  }, [deleteImage]);

  return (
    <section>
      {loading === true ? (
        <Loader />
      ) : (
        <>
          {overlay ? (
            <div className="loader-container" id="display">
              <Check
                setDeleteProject={setDeleteImage}
                setDisplayOverlay={setDisplayOverlay}
              />
            </div>
          ) : (
            <></>
          )}
          <div className="container">
            <Heading text="Project Images" />
            <MainImage
              res={res}
              setDisplayOverlay={setDisplayOverlay}
              setImageId={setImageId}
              setSelectedType={setSelectedType}
            />
            <div className="col-lg-12">
              <div className="input-com-group">
                <label className="label-class">Gallery Images</label>
                <div className="add-bg-image">
                  {gImgOne ? (
                    <img
                      style={{ width: "32px" }}
                      src={process.env.PUBLIC_URL + "/images/correct.png"}
                      alt=""
                    />
                  ) : (
                    <img
                      style={{ width: "32px" }}
                      src={process.env.PUBLIC_URL + "/images/add.png"}
                      alt=""
                    />
                  )}
                  <input
                    type="file"
                    id="galleryOne"
                    onChange={(event) => handleImageChange(event, "gallery")}
                    name="images[]"
                    required
                    multiple
                    accept="image/webp"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-4 offset-lg-4">
              <button className="action-btn mt-20 mb-20" onClick={fnEditImage}>
                Update Images
              </button>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-4 offset-lg-4">
              <button
                className="action-btn mt-20 mb-20"
                onClick={addGalleryImages}
              >
                Add Images
              </button>
            </div>
          </div>
          <div className="container mt-50">
            <Heading text="Cover Images" />
            <CoverImage
              res={res}
              setDisplayOverlay={setDisplayOverlay}
              setImageId={setImageId}
              setSelectedType={setSelectedType}
              setCoverMap={setCoverMap}
            />
            <div className="col-lg-12">
              <div className="input-com-group">
                <label className="label-class">Cover Photos</label>
                <div className="add-bg-image">
                  {coverOne ? (
                    <img
                      style={{ width: "32px" }}
                      src={process.env.PUBLIC_URL + "/images/correct.png"}
                      alt=""
                    />
                  ) : (
                    <img
                      style={{ width: "32px" }}
                      src={process.env.PUBLIC_URL + "/images/add.png"}
                      alt=""
                    />
                  )}
                  <input
                    type="file"
                    id="coverPhoto"
                    onChange={(event) => handleImageChange(event, "cover")}
                    name="coverImage[]"
                    required
                    multiple
                    accept="image/webp"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-4 offset-lg-4">
              <button className="action-btn mt-20 mb-20" onClick={fnEditImage}>
                Update Images
              </button>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-4 offset-lg-4">
              <button
                className="action-btn mt-20 mb-20"
                onClick={addCoverImages}
              >
                Add Images
              </button>
            </div>
          </div>
        </>
      )}
    </section>
  );
}
export default EditImages;
