import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import CleanLoader from "../../Common/CleanLoader";
import Heading from "../../Common/Heading";
import Loader from "../../Common/Loader";
import { ChromePicker } from "react-color";
import { uploadFileToCloudinary } from "../../../services/cloudinary";

function ProjectEdit() {
  const projectId = window.location.pathname.slice(22);

  const [projectData, setProjectData] = useState();

  const [projectName, setProjectName] = useState("");
  const [year, setYear] = useState("");
  const [description, setDescription] = useState("");
  const [country, setCountry] = useState("");
  const [priority, setPriority] = useState("");
  const [category, setCategory] = useState("");
  const [type, setType] = useState("");
  const [categorySelected, setCategorySelected] = useState();
  const [primary, setPrimary] = useState("");
  const [secondary, setSecondary] = useState("");
  const [tertiary, setTertiary] = useState("");
  const [client, setClient] = useState();
  const [clientId, setClientId] = useState();
  const [coverImage, setCoverImage] = useState();
  const [gallery, setGallery] = useState();
  const [feedback, setFeedback] = useState("");
  const [clientName, setClientName] = useState("");
  const [area, setArea] = useState("");

  const [primaryButton, setPrimaryButton] = useState(false);
  const [secondaryButton, setSecondaryButton] = useState(false);
  const [tertiaryButton, setTertiaryButton] = useState(false);
  const [clientsData, setClientsData] = useState();

  const btoa = window.btoa(
    `${process.env.REACT_APP_PERSONAL_USERNAME}:${process.env.REACT_APP_PERSONAL_PASSWORD}`
  );

  const [errorBorder, setErrorBorder] = useState(false);
  const [selected, setSelected] = useState(0);
  const [loading, setLoading] = useState(false);
  const [coverOne, setCoverOne] = useState(false);
  const [gImgOne, setGImageOne] = useState(false);

  const fetchClients = async () => {
    try {
      const clientData = await fetch(
        `${process.env.REACT_APP_BASE_URL}/client/`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Basic " + btoa,
          },
        }
      );
      const response = await clientData.json();
      setClientsData(response);

      console.log(response);
    } catch (error) {
      console.log(console.error);
    }
  };

  const fetchProjectData = async () => {
    try {
      const projectData = await fetch(
        `${process.env.REACT_APP_BASE_URL}/project/${projectId}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Basic " + btoa,
          },
        }
      );
      const res = await projectData.json();
      console.log(res);
      setProjectData(res);
      setProjectName(res?.name);
      setDescription(res?.description);
      setPriority(res?.priority);
      setCountry(res?.location);
      setType(res?.type);
      setYear(res?.date);
      setPrimary(res?.primary);
      setSecondary(res?.secondary1);
      setTertiary(res?.secondary2);
      setCoverImage(res?.coverImages);
      setGallery(res?.images);
      setClient(res?.feedback?.client);
      setCategory(res?.category);
      setFeedback(res?.feedback?.feedback);
      setArea(res?.area);
    } catch (error) {
      console.log(console.error);
    }
  };

  const checkError = (e) => {
    if (Number(e) > 10) {
      setErrorBorder(true);
    } else {
      setErrorBorder(false);
    }
  };

  useEffect(() => {
    fetchProjectData();
    fetchClients();
  }, []);

  let navigate = useNavigate();

  const editForm = async () => {
    setLoading(true);

    const formData = new FormData();

    formData.append("name", projectName);
    formData.append("priority", priority);
    formData.append("location", country);
    formData.append("description", description);
    formData.append("date", year);
    formData.append("area", area);
    // if (categorySelected === undefined) {
    //   formData.append("category", category[0]);
    // } else {
    //   formData.append("category", categorySelected);
    // }
    formData.append("type", type);
    formData.append("category", category);
    formData.append("primary", primary);
    formData.append("secondary1", secondary);
    formData.append("secondary2", tertiary);
    formData.append("client", client);
    try {
      const teamData = await fetch(
        `${process.env.REACT_APP_BASE_URL}/project/${projectId}`,
        {
          method: "PUT",
          body: formData,
          headers: {
            Authorization: "Basic " + btoa,
          },
        }
      );
      const res = await teamData.json();
      console.log(res);
      if (teamData?.status === 200) {
        setLoading(false);
        navigate("/project");
      }
    } catch (error) {
      console.log(console.error);
    }
  };

  useEffect(() => {
    if (projectData !== undefined && clientsData !== undefined) {
      let ans = clientsData.filter(
        (i) => i?.id === projectData?.feedback.client
      );
      setClientName(ans[0]?.name);
    }
  });
  // useEffect(() => {
  //   if (selected !== undefined && clients !== undefined) {
  //     setClientId(clients[selected]?.id);
  //   }
  // }, [selected]);

  return (
    <section>
      {projectData !== undefined ? (
        <>
          {loading === true ? (
            <Loader />
          ) : (
            <div className="container">
              <Heading text="Edit Project" />
              <div className="row">
                <div className="col-lg-6">
                  <div className="input-com-group">
                    <label className="lable-class">Name</label>
                    <input
                      className="input-group"
                      type="text"
                      value={projectName}
                      onChange={(e) => setProjectName(e.target.value)}
                      required
                    />
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="input-com-group">
                    <label className="lable-class">Year</label>
                    <input
                      className="input-group"
                      type="date"
                      value={year}
                      onChange={(e) => setYear(e.target.value)}
                      required
                    />
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="input-com-group">
                    <label className="lable-class">Description</label>
                    <textarea
                      className="input-group"
                      type="text"
                      value={description}
                      onChange={(e) => setDescription(e.target.value)}
                      required
                    />
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="input-com-group">
                    <label className="lable-class">Location</label>
                    <input
                      className="input-group"
                      type="text"
                      value={country}
                      onChange={(e) => setCountry(e.target.value)}
                      required
                    />
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="input-com-group">
                    <label className="lable-class">Priority</label>
                    <input
                      className={`input-group ${
                        errorBorder ? "error-border" : ""
                      }`}
                      type="number"
                      min={1}
                      minLength={1}
                      maxLength={10}
                      max={10}
                      onChange={(e) => {
                        setPriority(e.target.value);
                        checkError(e.target.value);
                      }}
                      value={priority}
                      required
                    />
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="input-com-group">
                    <label className="lable-class">Client</label>
                    <input
                      className="input-group"
                      value={clientName}
                      disabled={true}
                    />
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="input-com-group">
                    <label className="lable-class">Area</label>
                    <input
                      className="input-group"
                      value={area}
                      onChange={(e) => setArea(e.target.value)}
                    />
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="input-com-group">
                    <label className="lable-class">Feedback</label>
                    <input
                      placeholder="Feedback"
                      className="input-group"
                      type="text"
                      required
                      value={feedback}
                      // onChange={(e) => setFeedback(e.target.value)}
                      disabled={true}
                    />
                  </div>
                </div>

                <div className="col-lg-6">
                  <div className="input-com-group">
                    <label className="lable-class">Category</label>
                    <select
                      defaultValue="Select Category"
                      className="input-group"
                      value={type}
                      onChange={(e) => {
                        setType(e.target.value);
                      }}
                    >
                      <option>Select Category</option>
                      <option>Revit/AutoCAD</option>
                      <option>Lumion/D5</option>
                      <option>SketchUp</option>
                      <option>BlueBeam</option>
                      <option>CostX</option>
                      <option>PlanSwift</option>
                    </select>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="input-com-group">
                    <label className="lable-class">Sub Category</label>

                    <select
                      defaultValue="Commercial"
                      className="input-group"
                      value={category}
                      onChange={(e) => {
                        setCategory(e.target.value);
                      }}
                    >
                      <option>Select Sub Category</option>
                      <option>Commercial</option>
                      <option>Residential</option>
                    </select>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="input-com-group">
                    <label className="lable-class">Primary</label>
                    <button
                      className="position-relative input-group"
                      onClick={() => setPrimaryButton(!primaryButton)}
                    >
                      {primary}
                    </button>
                    {primaryButton === true ? (
                      <div className="color-picker">
                        <ChromePicker
                          style={{ width: "100%" }}
                          color={primary}
                          onChange={(color) => {
                            setPrimary(color.hex);
                          }}
                        />
                      </div>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="input-com-group">
                    <label className="lable-class">Secondary</label>
                    <button
                      className="position-relative input-group"
                      onClick={() => setSecondaryButton(!secondaryButton)}
                    >
                      {secondary}
                    </button>
                    {secondaryButton ? (
                      <div className="color-picker">
                        <div className="color-picker">
                          <ChromePicker
                            style={{ width: "100%" }}
                            color={secondary}
                            onChange={(color) => {
                              setSecondary(color.hex);
                            }}
                          />
                        </div>
                      </div>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="input-com-group">
                    <label className="lable-class">Tertiary</label>
                    <button
                      className="position-relative input-group"
                      onClick={() => setTertiaryButton(!tertiaryButton)}
                    >
                      {tertiary}
                    </button>
                    {tertiaryButton === true ? (
                      <div className="color-picker">
                        <ChromePicker
                          style={{ width: "100%" }}
                          color={tertiary}
                          onChange={(color) => {
                            setTertiary(color.hex);
                          }}
                        />
                      </div>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
              </div>
              {/* <div className="border-yellow">
                <div className="row">
                  {gImgOne ? (
                    <></>
                  ) : (
                    <div className="col-lg-8">
                      {gallery?.map((i, index) => (
                        <img
                          width="100%"
                          key={index}
                          src={i.image_url ? i.image_url : i?.image}
                          alt="cover"
                        />
                      ))}
                    </div>
                  )}
                  <div className={`${gImgOne ? "col-lg-12" : "col-lg-4"}`}>
                    <div className="input-com-group">
                      <label className="label-class">Gallery Images</label>
                      <div className="add-bg-image">
                        {gImgOne ? (
                          <img
                            style={{ width: "32px" }}
                            src={process.env.PUBLIC_URL + "/images/correct.png"}
                            alt=""
                          />
                        ) : (
                          <img
                            style={{ width: "32px" }}
                            src={process.env.PUBLIC_URL + "/images/edit.png"}
                            alt=""
                          />
                        )}
                        <input
                          onChange={() => setGImageOne(true)}
                          type="file"
                          name="images[]"
                          id="galleryOnes"
                          required
                          multiple
                          accept="image/webp"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="border-yellow">
                <div className="row">
                  {coverOne ? (
                    <></>
                  ) : (
                    <div className="col-lg-8">
                      {coverImage?.map((i, index) => (
                        <img
                          width="100%"
                          key={index}
                          src={i.image_url ? i.image_url : i?.image}
                          alt="cover"
                        />
                      ))}
                    </div>
                  )}
                  <div className={`${coverOne ? "col-lg-12" : "col-lg-4"}`}>
                    <div className="input-com-group">
                      <label className="label-class">Cover Photos</label>
                      <div className="add-bg-image">
                        {coverOne === true ? (
                          <img
                            style={{ width: "32px" }}
                            src={process.env.PUBLIC_URL + "/images/correct.png"}
                            alt=""
                          />
                        ) : (
                          <img
                            style={{ width: "32px" }}
                            src={process.env.PUBLIC_URL + "/images/edit.png"}
                            alt=""
                          />
                        )}
                        <input
                          type="file"
                          id="coverPhotos"
                          onChange={() => setCoverOne(true)}
                          name="coverImage[]"
                          required
                          multiple
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div> */}
              <div className="row">
                <div className="col-lg-4 offset-lg-4">
                  <button className="action-btn mt-20 mb-20" onClick={editForm}>
                    Update Changes
                  </button>
                </div>
              </div>
            </div>
          )}
        </>
      ) : (
        <CleanLoader />
      )}
    </section>
  );
}

export default ProjectEdit;
