import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import CleanLoader from "../../Common/CleanLoader";
import Heading from "../../Common/Heading";

function Edit() {
  const [memberData, setMemberData] = useState();
  const [memberName, setMemberName] = useState();
  const [name, setName] = useState("");
  const [designation, setDesignation] = useState("");
  const [linkedin, setLinkedin] = useState("");
  let navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [disable, setDisable] = useState(true);
  let pathname = window.location.pathname.slice(16);
  const btoa = window.btoa(
    `${process.env.REACT_APP_PERSONAL_USERNAME}:${process.env.REACT_APP_PERSONAL_PASSWORD}`
  );
  function handleName(e) {
    setName(e.target.value);
  }
  function handleDesignation(e) {
    setDesignation(e.target.value);
  }
  function handleLinkedin(e) {
    setLinkedin(e.target.value);
  }

  const fetchUserDetail = async () => {
    try {
      const teamData = await fetch(
        `${process.env.REACT_APP_BASE_URL}/member/${pathname}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Basic " + btoa,
          },
        }
      );
      const res = await teamData.json();
      setMemberData(res);
      setName(res?.name);
      setDesignation(res?.designation);
      setLinkedin(res?.linkedin);
      setMemberName(res?.name.split(" ", 2));
      if (teamData?.status === 200) {
        setLoading(false);
      }
    } catch (error) {
      console.log(console.error);
    }
  };

  useEffect(() => {
    fetchUserDetail();
  }, []);

  const onFormSubmit = async () => {
    setLoading(true);
    setDisable(true);
    const imageID = document.querySelector("#image");
    const formData = new FormData();
    // formData.append("image", imageID.files[0]);
    formData.append("name", name);
    formData.append("designation", designation);
    formData.append("linkedin", linkedin);
    try {
      const teamData = await fetch(
        `${process.env.REACT_APP_BASE_URL}/member/${pathname}`,
        {
          method: "PUT",
          body: formData,
          headers: {
            Authorization: "Basic " + btoa,
          },
        }
      );
      const res = await teamData.json();
      console.log(res);
      if (teamData?.status === 200) {
        setLoading(false);
        navigate("/team");
      }
    } catch (error) {
      console.log(console.error);
    }
  };
  return (
    <section>
      {memberData === undefined ? (
        <CleanLoader />
      ) : (
        <div className="container">
          <Heading text={`Edit ${memberName[0]}'s Detail`} />
          <div className="row">
            <div className="col-lg-8">
              <div className="row">
                <div className="col-lg-6">
                  <div className="input-com-group">
                    <label className="lable-class">Name</label>
                    <input
                      className="input-group"
                      type="text"
                      minLength="1"
                      maxLength="255"
                      value={name}
                      onChange={handleName}
                    />
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="input-com-group">
                    <label className="lable-class">Designation</label>
                    <input
                      value={designation}
                      className="input-group"
                      type="text"
                      minLength="1"
                      maxLength="255"
                      onChange={handleDesignation}
                      required
                    />
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="input-com-group">
                    <label className="lable-class">Linkedin</label>
                    <input
                      value={linkedin}
                      className="input-group"
                      type="url"
                      minLength="1"
                      maxLength="255"
                      onChange={handleLinkedin}
                      required
                    />
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="input-com-group">
                    <label className="lable-class">Upload New Picture</label>
                    <input
                      placeholder="Enter Designation"
                      className="input-group"
                      type="file"
                      name="image[]"
                      id="image"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="input-com-group">
                <div className="edit-profile-img-container">
                  <img
                    style={{ width: "100%" }}
                    src={memberData?.image}
                    alt="profile"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-4 offset-lg-4">
              <button
                type="submit"
                onClick={onFormSubmit}
                className={`action-btn ${
                  loading === true ? "opacity-03" : "opacity-01"
                }`}
                disabled={loading}
                // disabled={disable}
              >
                Add Team
                <span
                  className={`${
                    loading === false ? "" : "spinner-border spinner-border-sm"
                  } `}
                  style={{ marginLeft: "5px" }}
                ></span>
              </button>
            </div>
          </div>
        </div>
      )}
    </section>
  );
}

export default Edit;
