import React from "react";

function SmallLoader() {
  return (
    <>
      <div className="loader-mini-container">
        <div className="loader-mini"></div>
      </div>
    </>
  );
}

export default SmallLoader;
