import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Heading from "../../Common/Heading";
import Loader from "../../Common/Loader";
import DesignationInput from "./DesignationInput";
import ImageInput from "./ImageInput";
import LinkedinInput from "./LinkedinInput";
import NameInput from "./NameInput";

function AddTeam() {
  const [name, setName] = useState("");
  const [designation, setDesignation] = useState("");
  const [linkedin, setLinkedin] = useState("");
  const [loading, setLoading] = useState(false);
  const [disable, setDisable] = useState(true);

  const btoa = window.btoa(
    `${process.env.REACT_APP_PERSONAL_USERNAME}:${process.env.REACT_APP_PERSONAL_PASSWORD}`
  );

  let navigate = useNavigate();

  const onFormSubmit = async () => {
    setLoading(true);
    setDisable(true);
    const imageID = document.querySelector("#image");
    const formData = new FormData();
    formData.append("image", imageID.files[0]);
    formData.append("name", name);
    formData.append("designation", designation);
    formData.append("linkedin", linkedin);
    try {
      const teamData = await fetch(
        `${process.env.REACT_APP_BASE_URL}/member/`,
        {
          method: "POST",
          body: formData,
          headers: {
            Authorization: "Basic " + btoa,
          },
        }
      );
      const res = await teamData.json();
      if (teamData?.status === 201) {
        setLoading(false);
        navigate("/team");
      }
    } catch (error) {
      console.log(console.error);
    }
  };

  useEffect(() => {
    if (name !== "" && designation !== "" && linkedin !== "") {
      setDisable(false);
    }
  });

  return (
    <>
      {loading ? <Loader /> : <></>}
      <div className="container" style={{ position: "relative" }}>
        <Heading text="Add Team Member" />
        <div className="row">
          <NameInput setName={setName} />
          <DesignationInput setDesignation={setDesignation} />
          <LinkedinInput setLinkedin={setLinkedin} />
          <ImageInput />
        </div>
        <div className="row">
          <div className="col-lg-4 offset-lg-4">
            <button
              type="submit"
              onClick={onFormSubmit}
              className={`action-btn ${
                disable === true ? "opacity-03" : "opacity-01"
              }`}
              disabled={disable}
            >
              Add Team
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

export default AddTeam;
